import Vue from 'vue'
import Vuex from 'vuex'
import preferences, { Actions as PrefActions } from './preferences'
import application, { Actions as AActions } from './application'
import session, { Actions as SActions } from './session'
import profile, { Actions as PActions } from './profile'
import users, { Actions as UActions } from './modules/users'
import posts, { Actions as PSActions } from './modules/posts'
import products, { Actions as PRActions } from './modules/products'
import chats, { Actions as CActions } from './modules/chats'
import checkout, { Actions as CheckActions } from './modules/checkout'
import withdraws, { Actions as WActions } from './withdraws'
import revolution, { Actions as RActions } from './revolution'
import stories, { Actions as StActions } from './modules/stories'
import campaign, { Actions as CPActions } from './campaign'

Vue.use(Vuex)

const debug = process.env.NODE_ENV !== 'production'

const store = new Vuex.Store({
  modules: {
    [preferences.namespaceName]: preferences,
    [application.namespaceName]: application,
    [session.namespaceName]: session,
    [profile.namespaceName]: profile,
    [users.namespaceName]: users,
    [posts.namespaceName]: posts,
    [products.namespaceName]: products,
    [chats.namespaceName]: chats,
    [withdraws.namespaceName]: withdraws,
    [checkout.namespaceName]: checkout,
    [revolution.namespaceName]: revolution,
    [stories.namespaceName]: stories,
    [campaign.namespaceName]: campaign
  },
  actions: {
    reset ({ commit }) {
      commit(`${preferences.namespaceName}/reset`)
      commit(`${session.namespaceName}/reset`)
      commit(`${application.namespaceName}/reset`)
      commit(`${profile.namespaceName}/reset`)
      commit(`${users.namespaceName}/reset`)
      commit(`${posts.namespaceName}/reset`)
      commit(`${chats.namespaceName}/reset`)
      commit(`${withdraws.namespaceName}/reset`)
      commit(`${checkout.namespaceName}/reset`)
      commit(`${revolution.namespaceName}/reset`)
      commit(`${stories.namespaceName}/reset`)
      commit(`${campaign.namespaceName}/reset`)
    }
  },
  strict: debug
})

function mapActions (namespace, actions) {
  const mappedActions = {}

  Object.entries(actions).forEach(([key, value]) => {
    mappedActions[key] = `${namespace}/${value}`
  })

  return mappedActions
}

/*
if (navigator.serviceWorker) {
  navigator.serviceWorker.register('service-worker.js').then(function(registration) {
    console.log('ServiceWorker registration successful with scope:',  registration.scope);
  }).catch(function(error) {
    console.log('ServiceWorker registration failed:', error);
  });
}
*/
const actions = {
  PreferenceActions: mapActions(preferences.namespaceName, PrefActions),
  AppActions: mapActions(application.namespaceName, AActions),
  SessionActions: mapActions(session.namespaceName, SActions),
  ProfileActions: mapActions(profile.namespaceName, PActions),
  UserActions: mapActions(users.namespaceName, UActions),
  PostActions: mapActions(posts.namespaceName, PSActions),
  ProductActions: mapActions(products.namespaceName, PRActions),
  ChatActions: mapActions(chats.namespaceName, CActions),
  WithdrawActions: mapActions(withdraws.namespaceName, WActions),
  CheckoutActions: mapActions(checkout.namespaceName, CheckActions),
  RevolutionActions: mapActions(revolution.namespaceName, RActions),
  StoriesActions: mapActions(stories.namespaceName, StActions),
  CampaignActions: mapActions(campaign.namespaceName, CPActions)
}
// mediators(store, actions)

export const PreferenceActions = actions.PreferenceActions
export const AppActions = actions.AppActions
export const SessionActions = actions.SessionActions
export const ProfileActions = actions.ProfileActions
export const UserActions = actions.UserActions
export const PostActions = actions.PostActions
export const ProductActions = actions.ProductActions
export const ChatActions = actions.ChatActions
export const WithdrawActions = actions.WithdrawActions
export const CheckoutActions = actions.CheckoutActions
export const RevolutionActions = actions.RevolutionActions
export const StoriesActions = actions.StoriesActions
export const CampaignActions = actions.CampaignActions
export default store
