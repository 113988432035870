<template>
  <div v-show="shouldShowModal">
    <iframe ref="myIframe" :src="campaignModalUrl"
            style="position:fixed; top:0px; left:0px; bottom:0px; right:0px; width:100%; height:100%; border:none; margin:0; padding:0; overflow:hidden; z-index:999999;"
    />
  </div>
</template>

<script>
import project from '@/project'
import messages from '@/views/landing/specialCampaign/locales/all.json'
import {mapState} from 'vuex'
import {CampaignActions} from "@/store"

export default {
  name: 'SpecialCampaingDialog',
  i18n: {messages},

  data() {
    return {
      project
    }
  },
  computed: {
    ...mapState('profile', ['currentUser', 'isFollower']),
    ...mapState('preferences', ['darkTheme']),
    ...mapState('application', ['isMobile']),
    ...mapState('campaign', ['payload']),
    campaignModalUrl() {
      return `${project.domains.baseUrl}/campaigns/promo/${this.payload.filename}/modal.html?follower=${this.isFollower}`
    },
    isDark() {
      return this.project.theme === 'dark' || this.darkTheme
    },
    shouldShowModal() {
      return this.$store.getters['campaign/shouldShowModal']
    },
  },

  methods: {
    controllerMessage(event) {
      if (event.data === 'doNotShowMore') {
        this.notShow()
      }
      if (event.data === 'close') {
        this.close()
      }
    },
    close() {
      this.$store.dispatch(CampaignActions.close)
    },
    notShow() {
      this.$store.dispatch(CampaignActions.notShow)
    }
  },
  mounted() {
    this.dialog = true
    const message = {
      type: 'profile',
      data: this.currentUser
    }
    const iframe = this.$refs.myIframe
    iframe.onload = function () {
      iframe.contentWindow.postMessage(message, '*')
    }
    window.addEventListener('message', this.controllerMessage)
  },
  destroyed() {
    window.removeEventListener('message', this.controllerMessage)
  }
}
</script>
