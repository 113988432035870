var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',[_c('PaymentStatistics',{attrs:{"filter":_vm.query,"agents":""}}),_c('v-data-table',{attrs:{"hide-default-footer":_vm.noPagination,"headers":_vm.headers,"items":_vm.items,"loading":_vm.loading,"item-key":"id","page":_vm.page,"items-per-page":_vm.perPage,"server-items-length":_vm.totalItems},on:{"update:page":function($event){_vm.page=$event},"update:itemsPerPage":function($event){_vm.perPage=$event},"update:items-per-page":function($event){_vm.perPage=$event}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('ListViewTitle',{attrs:{"is-title":_vm.noTitle,"title":_vm.tableTitle,"action":_vm.titleAction}}),(!_vm.noFilter)?_c('v-row',{staticClass:"px-2",attrs:{"align":"center"}},[_c('v-col',{attrs:{"cols":"6","sm":"3"}},[(!_vm.usernameProp)?_c('v-text-field',{attrs:{"rounded":"","outlined":"","dense":"","hide-details":"","clearable":"","append-icon":"filter_list","label":_vm.$t('actions.filter')},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}):_vm._e()],1),_c('v-col',{attrs:{"cols":"6","sm":"3"}},[_c('date-picker-input',{attrs:{"range":"","dense":"","hide-details":"","rounded":"","label":_vm.$t('labels.filter_dates')},model:{value:(_vm.filterDates),callback:function ($$v) {_vm.filterDates=$$v},expression:"filterDates"}})],1)],1):_vm._e()]},proxy:true},{key:"item.billedAt",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.getDate(item.billedAt)))])]}},{key:"item.user",fn:function(ref){
var item = ref.item;
return [_c('router-link',{staticClass:"no-decoration",attrs:{"to":{ name: 'influencer', params: { username: item.user } }}},[_vm._v(" "+_vm._s(("@" + (item.user)))+" ")])]}},{key:"item.receiver",fn:function(ref){
var item = ref.item;
return [(item.user !== item.receiver)?_c('router-link',{staticClass:"no-decoration",attrs:{"to":{ name: 'admin_user', params: { username: item.receiver } }}},[_vm._v(" "+_vm._s(("@" + (item.receiver)))+" ")]):_c('span',[_vm._v("-")])]}},{key:"item.creditCard",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.creditCard ? 'Si' : 'No')+" ")]}},{key:"item.resource",fn:function(ref){
var item = ref.item;
return [(_vm.isSale(item))?_c('router-link',{attrs:{"to":("/" + (item.resource) + "/" + (item.resourceId))}},[_vm._v(" "+_vm._s(_vm.$t('actions.show_item', {item: _vm.$t(("models." + (item.resource) + ".singular_name")).toLowerCase()}))+" ")]):_c('span',[_vm._v(" "+_vm._s(_vm.$i18n.te(("models.payment.types." + (item.resource))) ? _vm.$t(("models.payment.types." + (item.resource))) : item.resource)+" ")])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }