<template>
  <section class="user-card"
    :class="{ expand: isMobile }">
    <c-img class="user-card-background bordered rounded"
      no-resize
      :src="user.bannerS3Route"
      gradient="to bottom, rgba(0, 0, 0, 0.3), #000000">
    </c-img>
    <div class="user-card-wrapper">
      <UserAvatar
        vertical center
        x-large
        :user="user">
        <div slot="name" class="white--text center">
          {{ user.displayName }}
          <img v-if="user.trustedProfile" src="/images/check5.svg" height="15"/></div>
        <div slot="username" class="white-light-text">{{ `@${user.username}` }}</div>
      </UserAvatar>
      <div v-if="isInfluencer"
        class="user-card-actions">
        <PaymentBtn
          small
          subscription
          :outlined="darkTheme"
          :isProfile="isProfile"
          color="white"
          css="align-self-center"
          :avaiblable="isSubscribable"
          :resource="user" />
          <btn-video-call :user="user"/>
        <FollowingBtn
          css="align-self-center"
          small outlined color="white"
          :user="user" />

      </div>
    </div>
  </section>
</template>
<script>

import { mapState } from 'vuex'
import UserAvatar from '@/components/users/UserAvatar'
import PaymentBtn from '@/components/payments/PaymentButton'
import FollowingBtn from '@/components/users/FollowingButton'

export default {
  name: 'LargeCard',
  components: { UserAvatar, PaymentBtn, FollowingBtn },
  props: {
    user: Object,
    isProfile: Boolean
  },
  computed: {
    ...mapState('preferences', ['darkTheme']),
    ...mapState('application', ['isMobile']),
    isInfluencer () {
      return this.user.role === 'influencer'
    },
    isSubscribable () {
      return this.user.subscribable
    }
  }
}
</script>
<style lang="scss">
@use 'src/scss/colors';

.user-card {
  position: relative;
  height: 315px;
  margin: 0 auto;
  display: flex;
  align-items: flex-end;
  &.expand {
    max-width: 80%;
    width: 80%;
  }
  .user-card-background {
    position: absolute;
    top: 64px;
    left: 0;
    right: 0;
    bottom: 0;
  }

  .user-card-wrapper {
    max-width: 100%;
    width: 100%;
    height: 250px;
    padding: 12px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    z-index: 0;
    .user-avatar {
      margin-top: -76px;
    }
    .user-card-actions {
      display: flex;
      flex-direction: column;
      margin-left: auto;
      margin-right: auto;
      text-align: center;

      > * {
        margin-top: 5px;
      }
    }
  }
}

  .rank  {
    min-height: fit-content;
    div{
      color: white;
      vertical-align: center;
      margin: auto;
    }
  }
</style>
