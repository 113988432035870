<template>
  <section
    class="box-warning-dialog"
    ref="warning"
    :class="classCss"
    :tabindex="1" @keyup.esc="handlerButtonCancel"
  >
    <section class="warning-dialog" :class="dark" >
      <slot></slot>
      <div style="color: #000000">
        {{ textWarning }}
      </div>
      <div class="footerWarning">
        <Button
          v-if="!hidenButton"
          :text="textButtonOK"
          :color="colorButtonOK"
          @handler="handlerButtonOK"
          :loading="loading"
        />
        <Button
          v-if="!hideCancelButton"
          :text="textButtonCancel"
          :color="colorButtonCancel"
          @handler="handlerButtonCancel"
        />
      </div>
      <slot name='plus'/>
    </section>
  </section>
</template>
<script>
import Button from './Buttons.vue'
export default {
  name: 'WarningDialog',
  props: {
    show: { type: Boolean, default: true },
    textWarning: { type: String, default: 'Test WarningDialog' },
    textButtonCancel: { type: String, default: 'CANCELAR' },
    textButtonOK: { type: String, default: 'Botton OK' },
    colorButtonCancel: { type: String, default: '#f44336' },
    colorButtonOK: { type: String, default: '#4fbf39' },
    hidenButton: { type: Boolean, default: false },
    hideCancelButton: { type: Boolean, default: false },
    loading: { type: Boolean, default: false }
  },
  components: {
    Button
  },
  computed: {
    classCss () {
      return {
        show: this.show
      }
    },
    dark () {
      return {
        dark: this.$vuetify.theme.dark
      }
    }
  },
  mounted () {
    this.$refs.warning.focus()
  },
  methods:{
    handlerButtonOK(){
      this.$emit('handlerButtonOK')
    },
    handlerButtonCancel(){
      this.$emit('handlerButtonCancel')
    }
  }
}
</script>
<style lang="scss">
.show {
  display: flex !important;
}
.footerWarning {
  display: flex;
  margin-top: 2rem;
  margin-bottom: 1rem;
  justify-content: space-around;
}
.box-warning-dialog {
  width: 100%;
  position: fixed;
  top: 0;
  right: 0;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  display: none;
  justify-content: center;
  align-items: center;
  z-index: 11000;
}
.warning-dialog {
  width: auto;
  max-width: 80%;
  background-color: #ffffff;
  padding: 20px;
  border-radius: 15px;
  border: 0.2px #ffffff solid;
  position: absolute;
  &.dark {
    background-color: #272727 !important;
  }
}
</style>
