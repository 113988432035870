<template>
  <section>
    <PaymentStatistics :filter="query" agents/>
    <v-data-table
      :hide-default-footer="noPagination"
      :headers="headers"
      :items="items"
      :loading="loading"
      item-key="id"
      :page.sync="page"
      :items-per-page.sync="perPage"
      :server-items-length="totalItems">
      <template #top>
        <ListViewTitle :is-title="noTitle" :title="tableTitle"
                       :action="titleAction"/>
        <v-row v-if="!noFilter" align="center" class="px-2">
          <v-col cols="6" sm="3">
            <v-text-field
              v-if="!usernameProp"
              rounded
              outlined
              dense
              hide-details
              clearable
              append-icon="filter_list"
              :label="$t('actions.filter')"
              v-model="search">
            </v-text-field>
          </v-col>
          <v-col cols="6" sm="3">
            <date-picker-input
              range
              dense
              hide-details
              rounded
              :label="$t('labels.filter_dates')"
              v-model="filterDates">
            </date-picker-input>
          </v-col>
        </v-row>
      </template>
      <template #item.billedAt="{ item }">
        <span>{{ getDate(item.billedAt) }}</span>
      </template>
      <template #item.user="{ item }">
        <router-link :to="{ name: 'influencer', params: { username: item.user } }"
                     class="no-decoration">
          {{ `@${item.user}` }}
        </router-link>
      </template>
      <template #item.receiver="{ item }">
        <router-link v-if="item.user !== item.receiver"
                     :to="{ name: 'admin_user', params: { username: item.receiver } }"
                     class="no-decoration">
          {{ `@${item.receiver}` }}
        </router-link>
        <span v-else>-</span>
      </template>
      <template #item.creditCard="{ item }">
        {{ item.creditCard ? 'Si' : 'No' }}
      </template>
      <template #item.resource="{ item }">
        <router-link v-if="isSale(item)" :to="`/${item.resource}/${item.resourceId}`">
          {{ $t('actions.show_item', {item: $t(`models.${item.resource}.singular_name`).toLowerCase()}) }}
        </router-link>
        <span v-else>
          {{ $i18n.te(`models.payment.types.${item.resource}`) ? $t(`models.payment.types.${item.resource}`) : item.resource }}
          </span>
      </template>
    </v-data-table>
  </section>
</template>
<script>
import PaymentApi from '@/api/admin/PaymentApi'
import table from '@/components/mixins/table'
import Utils from '@/utils'
import PaymentStatistics from '@/components/admin/payments/PaymentStatistics'

export default {
  name: 'PartnerPayments',
  components: {PaymentStatistics},
  mixins: [table(PaymentApi, 'represented')],
  props: {
    usernameProp: String,
    pageItems: {type: Number, default: 10},
    noTitle: {type: Boolean, default: true},
    noFilter: Boolean
  },
  data() {
    return {
      headers: [
        {text: this.$t('models.payment.billed_at'), value: 'billedAt', sortable: false},
        {text: this.$t('models.payment.user'), value: 'user', sortable: false},
        {text: this.$t('models.payment.receiver'), value: 'receiver', sortable: false},
        {text: this.$t('models.payment.resource'), value: 'resource', sortable: false},
        /*  { text: this.$t('models.payment.credit_card'), value: 'creditCard', sortable: false }, */
        /* { text: this.$t('models.payment.net_amount'), value: 'netAmount', sortable: false }, */
        {text: this.$t('models.payment.fee'), value: 'agentFee', sortable: false},
        {text: this.$t('models.payment.total_amount'), value: 'amount', sortable: false},
        /*   { text: this.$t('models.payment.vat'), value: 'vat', sortable: false }, */
      ],
      filterDates: [],
      search: undefined,
      perPage: 5,
      onlyCreditCard: undefined
    }
  },
  computed: {
    creditCard() {
      return this.onlyCreditCard ? true : undefined
    },

    tableTitle() {
      return this.title || this.$t('agents.monetization')
    },
    query() {
      const [after, before] = this.filterDates
      return {
        search: this.search,
        status: 'commited',
        creditCard: this.creditCard,
        billedAfter: after,
        billedBefore: before
      }
    },
    titleAction() {
      if (!this.noTitle) {
        return {
          text: this.$t('actions.show_all'),
          action: () => this.$router.push({name: 'partner_billing'})
        }
      }
    }
  },
  methods: {
    isSale(item) {
      return item.resource === 'products' || item.resource === 'posts'
    },
    getDate(date) {
      return Utils.formatDate(date)
    },
    mapItem(payment) {
      const vat = payment.amount * (payment.vat / 100)
      const fee = payment.amount * (payment.fee / 100)
      const affiliateFee = payment.amount * (payment.affiliateFee / 100)
      /*const feeplatform = payment.amount * (20 / 100)*/
      const totalAmount = payment.amount
      const netAmount = payment.amount - fee - affiliateFee
      const realAmount = totalAmount - fee - affiliateFee
      const agent = realAmount * (payment.agentFee / 100)
      return {
        ...payment,
        date: this.$datetime(payment.billedAt),
        country: this.$country(payment.country),
        netAmount: this.$currency(netAmount, payment.currency),
        fee: this.$currency(fee, payment.currency),
        vat: this.$currency(vat, payment.currency),
        amount: this.$currency(realAmount, payment.currency),
        agentFee: this.$currency(agent, payment.currency)

      }
    }
  },
  beforeMount() {
    this.perPage = this.pageItems
    this.search = this.usernameProp || this.$route.params.username || ''
  }
}
</script>
