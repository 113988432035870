<template>
  <section>
    <div style="text-align: center" v-if="loadingComponent">
      <Spinner color="primary" />
    </div>
    <span v-else>
      <div class="row" >
        <div class="col title-sect">
          <h5 class="tooltip">{{ $t('application.chat_setting.general.lang_select.title') }}
            <span class="tooltiptext">{{ $t('application.chat_setting.general.lang_select.tooltip') }}</span>
          </h5>
        </div>
        <div class="col item tooltip d-flex" style="max-width: 50%; width: fit-content" @click="showHideList()">
          <img :src="getLangFlagSelected" width="20" height="15" class="icons">
          <div style="font-family: Quicksand, sans-serif !important;margin-left: 5px;">{{$t(`flags.${getLangSelected.code}`)}} ( {{getLangSelected.language}} )</div>
            <span class="tooltiptext left">{{ $t('application.chat_setting.general.lang_select.tooltip_2') }} {{$t(`flags.${getLangSelected.code}`)}}</span>
        </div>
        <ListView
          v-if="showList"
          no-gutters
          class="custom-scrollbar lang-select"
          :style="{backgroundColor: darkTheme ? '#adadad' : '#b6b6b6'}"
          :items="languages"
          cols="12"
          item-class="mx-2 my-1 item"
          :dark="darkTheme"
          align="start"
          justify="start"
        >
          <template #header>
            <span style="margin-left: 5px; color: black; font-family: Quicksand, sans-serif !important; text-transform: capitalize;">{{ $t('words.choose_language') }}</span>
          </template>
          <template #default="{ item }" class="item">
            <span class="d-flex" @click="selectLang(item.code)">
              <img :src="item.image" width="20" height="15"><div style="margin-left: 5px; color: black !important;" ><span style="font-family: Quicksand, sans-serif !important; text-transform: capitalize;">{{$t(`flags.${item.code}`)}} ( {{item.language}} )</span></div>
            </span>
          </template>
        </ListView>
      </div>
      <div class="row">
        <div class="col title-sect">
          <h5 class="tooltip">{{ $t('application.chat_setting.general.videochat.title') }}
            <span class="tooltiptext">{{ $t('application.chat_setting.general.videochat.tooltip') }}</span>
          </h5>
        </div>
        <div class="col d-flex" style="max-width: 50%; width: fit-content">

        <Button
          :text="$t('application.chat_setting.general.videochat.btn_disable')" v-if="settings.videochatEnabled"
          @handler="setVideochat(false)"
        />
        <Button
          color="#f44336"
          :text="$t('application.chat_setting.general.videochat.btn_enable')" v-else
          @handler="setVideochat(true)"
        />
<!--          <button type="button" style="color: red; font-family: Quicksand, sans-serif !important;" @click="showWarningDialog(false)">{{ $t('application.chat_setting.general.delete_chats.btn') }}</button>-->
        </div>
      </div>
      <div class="row"
           v-if="isInfluencer">
        <div class="col title-sect">
          <h5 class="tooltip">{{ $t('application.chat_setting.general.delete_chats.title') }}
            <span class="tooltiptext">{{ $t('application.chat_setting.general.delete_chats.tooltip') }}</span>
          </h5>
        </div>
        <div class="col d-flex" style="max-width: 50%; width: fit-content">

        <Button
          :text="$t('application.chat_setting.general.delete_chats.btn')"
          @handler="showWarningDialog"
        />
<!--          <button type="button" style="color: red; font-family: Quicksand, sans-serif !important;" @click="showWarningDialog(false)">{{ $t('application.chat_setting.general.delete_chats.btn') }}</button>-->
        </div>
      </div>
    </span>
    <WarningDialog
      :show="showWarning"
      :textWarning="text"
      :textButtonOK="textButtonOk"
      @handlerButtonCancel="close"
      @handlerButtonOK="removeChat"
      :loading="loadingButton"
    />
  </section>
</template>

<script>
import { AppActions } from '@/store'
import Button from '@/components/chat/component/Buttons.vue'
import Spinner from '@/components/chat/component/Spinner'
import WarningDialog from '@/components/chat/component/WarningDialog'
import ConversationsApi from '@/api/conversations/ConversationsApi'
import {mapState} from "vuex";
export default {
  name: 'GeneralSettings',
  components: { Spinner, WarningDialog, Button },
  data () {
    return {
      loadingComponent: true,
      showList: false,
      languages: [],
      settings: {},
      selectedLang: {},
      showWarning: false,
      text: '',
      textButtonOk: '',
      loadingButton: false,
    }
  },
  computed: {
    ...mapState('profile', ['isInfluencer']),
    darkTheme () {
      return this.$vuetify.theme.dark
    },
    getLangFlagSelected () {
      return this.languages.filter(lang => lang.code === this.selectedLang)[0].image
    },
    getLangSelected () {
      return this.languages.filter(lang => lang.code === this.selectedLang)[0]
    },
    attrs () {
      return {
        followers: this.settings.followers,
        followersFree: this.settings.followersFree,
        fans: this.settings.fans,
        fansFree: this.settings.fansFree,
        others: this.settings.others,
        othersFree: this.settings.othersFree,
        followedByMe: this.settings.followedByMe,
        followedByMeFree: this.settings.followedByMeFree,
        messagePrice: this.settings.messagePrice,
        mediaPrice: this.settings.mediaPrice,
        videochatPrice: this.settings.videochatPrice,
        translateLanguage: this.selectedLang,
        videochatEnabled: this.settings.videochatEnabled
      }
    }
  },
  methods: {
    close () {
      this.showWarning = !this.showWarning
    },
    showHideList () {
      this.showList = !this.showList
    },
    selectLang (lang = null) {
      this.selectedLang = lang || this.languages[0].code
      this.updateSettings()
      this.showList = false
    },
    setVideochat (enable) {
      this.settings.videochatEnabled = enable
      this.updateSettings()
    },
    getLangs () {
      const langs = ConversationsApi.getLanguageTranslate().data
      for (var lang of langs) {
        this.languages.push({
          language: lang.language,
          image: lang.code === 'en' ? '/images/usa-flag-icon-20.jpg' : `https://flagcdn.com/28x21/${lang.flagCode.length > 2 ? lang.flagCode.substring(0, 2) : lang.flagCode}.png`,
          code: lang.code
        })
      }
    },
    showWarningDialog () {
      this.showList = false
      this.text = this.$t('chats.remove.all')
      this.textButtonOk = this.$t('actions.delete').toUpperCase()
      this.showWarning = true
    },
    async removeChat () {
      this.loadingButton = true
      const form = {
        removeAll: true
      }
      const { data, error } = await ConversationsApi.deleteConversationsBulk(form)
      if (!error) {
        this.$store.dispatch(AppActions.SetNotification, { type: 'success' })
      } else {
        this.$store.dispatch(AppActions.SetNotification, {
          type: 'error',
          error: data
        })
      }
      this.$store.dispatch(AppActions.ShowNotification, true)
      this.loadingButton = false
      this.close()
      this.reset()
      this.$emit('back')
    },
    async updateSettings () {
      const form = this.attrs
      const { data, error } = await ConversationsApi.updateSetting(form)
      if (!error) {
        this.$store.dispatch(AppActions.SetNotification, { type: 'success' })
      } else {
        this.$store.dispatch(AppActions.SetNotification, {
          type: 'error',
          error: data
        })
      }
      this.$store.dispatch(AppActions.ShowNotification, true)
    }
  },
  async mounted () {
    this.loadingComponent = true
    this.getLangs()
    const { data } = await ConversationsApi.settings()
    this.settings = data
    this.selectedLang = data.translateLanguage
    this.loadingComponent = false
  }
}
</script>

<style lang="scss" scoped>
.title-sect {
  max-width: 50%;
  width: fit-content;
  margin: auto;
}
.tooltip {
  position: relative;
  display: inline-block;
  font-family: Quicksand, sans-serif !important;
  font-size: 13px;
}

/* Tooltip text */
.tooltip .tooltiptext {
  font-family: Quicksand, sans-serif !important;
  visibility: hidden;
  width: 360px;
  background-color: #555;
  color: #fff;
  text-align: center;
  padding: 5px 0;
  border-radius: 6px;

  /* Position the tooltip text */
  position: absolute;
  z-index: 1;
  top: 125%;
  left: 50%;
  margin-left: -60px;

  &.left {
    width: 180px;
    top: 125% !important;
    right: 150% !important;
    left: 0% !important;
    margin-left: 0px !important;
  }

  /* Fade in tooltip */
  opacity: 0;
  transition: opacity 0.3s;
}

/* Tooltip arrow */
.tooltip .tooltiptext::after {
  content: "";
  position: absolute;
  top: -10%;
  left: 10%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent #555 transparent;
}

/* Show the tooltip text when you mouse over the tooltip container */
.tooltip:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
}

.lang-select {
  max-height: 160px;
  padding-bottom: 5px;
  overflow-y: auto;
  overflow-x: hidden;
  position: relative;
  z-index: 100;
  span:hover{
    width: -webkit-fill-available;
    background-color: #F0F0F0 !important;
    cursor: pointer;
  }
}

.item:hover {
  cursor: pointer;
}
</style>
