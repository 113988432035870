<template>
  <v-form
    v-model="validForm" ref="form"
    @submit.stop.prevent="submit">
    <template v-if="!isDraft">
      <FileUploader
        ref="uploader"
        class="mb-5"
        autoupload
        autoretry
        :contentType="getContentType"
        v-model="files"
        :isPromo="type === 'promo'"
        max-videos="1"
        :max-images="type === 'promo' ? 0 : 20"/>
      <FileInput v-if="canHaveTeaser"
                 class="d-block mb-4"
                 autoupload color="primary"
                 :preview="false"
                 v-model="teaser"
                 :label="$t('posts.form.teaser')"
                 :hint="teaserHint"
                 :error="hasTeaserError"
                 max-files="1"
                 contentType="video/*, .quicktime, .gif"
                 onlyVideo
                 @file-added="teaserAdded"/>
      <v-text-field
        outlined
        :label="$t('posts.form.title')"
        counter="100"
        :rules="[$vRequired, $vMaxLength(100), $vBannedWords(title), $vNoHtml(title), $vPhone(title)]"
        v-model="title">
      </v-text-field>
      <EmojiInput
        outlined required
        v-model="content"
        max-length="1500"
        :placeholder="$t('posts.form.content')"
        :rules="[$vRequired, $vBannedWords(content)]">
      </EmojiInput>
      <CategoriesInput v-model="categoryIds" :max="5"/>
      <v-row style="margin-bottom: -10px;">
        <v-col cols="auto">
          <span>{{ $t('posts.mentions.other_people') }}</span>
        </v-col>
      </v-row>
      <v-row align="center">
        <v-col cols="auto">
          <span>{{ $t('posts.mentions.text') }}</span>
        </v-col>
        <v-col cols="4">
          <c-btn small text
                 css="ma-2"
                 style="text-transform: Capitalize"
                 @click.stop="showMentionDialog">
            {{ $t('actions.add') }}
          </c-btn>
        </v-col>
      </v-row>
      <v-row>
        <v-chip v-for="user in mentions"
                :key="user"
                class="mx-1"
                dense outlined filter close
                @click:close="removeMentionedUser(user)">
          @{{ user }}
        </v-chip>
      </v-row>
      <EditParticipantList
        :participants="allParticipants"
        @change="updateParticipants"/>
      <v-row v-if="schedulable"
             align="center" justify="space-between">
        <v-col cols="auto">
          <v-icon>access_time</v-icon>
          {{ $t('models.post.choose_publish_at') }}
        </v-col>
        <v-col cols="auto">
          <v-switch color="primary" v-model="showDates"></v-switch>
        </v-col>
      </v-row>
      <v-row v-if="showDates">
        <v-col cols="12" md="6">
          <date-picker-input v-model="startDate"
                             :rules="[$vRequired]"
                             :label="$t('models.post.starts_date')"/>
        </v-col>
        <v-col cols="12" md="6">
          <time-picker-input v-model="startTime"
                             :rules="[$vRequired]"
                             :label="$t('models.post.starts_time')"/>
        </v-col>
        <v-col cols="12" v-if="isInvalidDate">
          <span class="small-text">
            {{ $t('models.posts.auto_publish') }}
          </span>
        </v-col>
      </v-row>
      <v-row align="center" class="mx-2">
        <v-autocomplete
          v-model="visibility"
          :items="availableVisibilities"
          :disabled="publicMandatory"
          :roles="[$vRequired]"
          item-text="text"
          item-value="value"
          outlined
          dense
          :label="$t('posts.form.visibility_message')"
          v-if="isLoverfans"
        >
<!--          <template v-slot:selection="{ item }">
            {{ item.value.charAt(0).toUpperCase() + item.value.slice(1) }}
          </template>-->
<!--          <template v-slot:item="{ item }">
            {{ item.text }}
          </template>-->
        </v-autocomplete>
        <span v-else>
          <v-col cols="auto" class="label small-text">
            <v-icon>visibility</v-icon>
            <span>{{ $t('posts.form.visibility_message') }}</span>
          </v-col>
          <v-col sm="5">
            <v-select outlined hide-details
                      class="d-inline-block"
                      v-model="visibility"
                      :disabled="publicMandatory"
                      :items="availableVisibilities"
                      :rules="[$vRequired]">
            </v-select>
          </v-col>
          <v-col cols="12" md="">{{ visibilityDescription }}</v-col>
        </span>
        <v-row style="text-align: center" v-if="canBePromotPost && isMobile">
          <v-col cols="3"></v-col>
          <v-col cols="1">
            <v-checkbox color="primary"
                        v-model="promoAvailable"
                        @change="checkPromoPost">
            </v-checkbox>
          </v-col>
          <v-col cols="5" style="text-align: left; margin-top: auto; margin-bottom: auto;">
            <a :href="`/images/promotions/june_2021_${checkLanguage ? 'esp' : 'eng'}.jpg`" target="_blank">
              {{ $t('publication.promo') }}
            </a>
          </v-col>
        </v-row>
      </v-row>
      <v-row class="mx-2" v-if="isPremium && isLoverfans" align="center">
        <CurrencyInput
          :label="$t('models.post.price')"
          outlined
          color="orange"
          v-model="price"
          hint="XXX,YY"
          :rules="[$vRequired]"
          minValue="500"
          maxValue="42000"/>
      </v-row>
      <v-row v-if="isPremium && !isLoverfans" align="center">
        <v-col cols="auto" class="label small-text">
          <v-icon>monetization_on</v-icon>
          <span>{{ $t('models.post.price') }}</span>
        </v-col>
        <v-col>
          <CurrencyInput
            outlined
            color="orange"
            v-model="price"
            hint="XXX,YY"
            :rules="[$vRequired]"
            minValue="500"
            maxValue="42000"/>
        </v-col>
      </v-row>
      <v-row v-if="isExclusive" align="center">
        <v-col cols="auto" class="label small-text">
          <v-icon>person</v-icon>
          <span>{{ $t('models.post.selected_users') }}</span>
        </v-col>
        <v-chip v-for="user in computedSelectedUsers"
                :key="user.text || user"
                class="mx-1"
                dense outlined filter close
                @click:close="removeSelectedUser(user.key || user)">
          {{ user.text || user }}
        </v-chip>
        <v-col cols="4">
          <c-btn small text
                 css="ma-2"
                 @click.stop="showDialog">
            {{ $t('actions.add') }}
          </c-btn>
        </v-col>
      </v-row>
    </template>
    <PostDraft v-else large skippable
               @continue="close('close')">
      {{ $t('posts.draft_hint_2') }}
    </PostDraft>
    <v-card-actions v-if="!isDraft"
                    class="justify-end">
<!--      <c-btn text color="secondary" v-if="!isLoverfans"-->
<!--             @click="close('close')">-->
<!--        {{ $t('actions.close') }}-->
<!--      </c-btn>-->
      <c-btn
        style="width: 100%"
        :disabled="!submitable"
        @click.stop.prevent="submit">
        {{ $t("actions.publish") }}
      </c-btn>
    </v-card-actions>
    <UserSearchDialog
      v-model="dialog"
      allow-fans-select
      allow-followers-select
      @multiple-selected="addMultipleUsers"
      @user-selected="addSelectedUser"/>
    <UserSearchDialog
      v-model="mentionDialog"
      @user-selected="addMentionedUser"/>
  </v-form>
</template>
<script>
import {mapState} from 'vuex'
import PostApi from '@/api/PostApi'
import validatable from '@/components/mixins/validatable'
import dialog from '@/components/mixins/dialog'
import UserSearchDialog from '@/components/users/UserSearchDialog'
import PostDraft from '@/components/posts/PostDraft'
import CategoriesInput from '@/components/custom/CategoriesInput'
import EditParticipantList from '@/components/posts/participants/EditParticipantList'
import config from "@/project";

const Visibilities = ['open', 'followers', 'premium', 'exclusive']
const MaxTeaserGifSize = 1024 * 1024 * 10 // 10MB

export default {
  name: 'PostForm',
  mixins: [validatable('form'), dialog],
  components: {EditParticipantList, UserSearchDialog, PostDraft, CategoriesInput},
  props: {
    schedulable: {type: Boolean, default: true},
    publicMandatory: {type: Boolean, default: false},
    attached: {type: String, default: null},
    type: String,
    allFans: Boolean,
    allFollowers: Boolean
  },
  data() {
    return {
      mentionDialog: false,
      title: '',
      content: '',
      visibility: '',
      selectedUsers: [],
      selectedAllFans: false,
      selectedAllFollowers: false,
      price: null,
      teaser: [],
      files: [],
      users: [],
      categoryIds: [],
      post: null,
      multiplePeople: false,
      finishedAddingPeople: false,
      participants: [],
      mentions: [],
      showDates: false,
      startDate: null,
      startTime: null,
      promoAvailable: false,
      teaserError: false,
      config
    }
  },
  computed: {
    ...mapState('application', ['isMobile']),
    ...mapState('profile', ['currentUser']),
    allParticipants() {
      return {current: [], deleted: [], added: this.participants}
    },
    isLoverfans() {
      return config.project === 'loverfans'
    },
    isNewFrontend () {
      return config.redirect_new_frontend
    },
    hasTeaserError() {
      return !!this.teaser.length && this.teaserError
    },
    teaserHint() {
      return this.hasTeaserError ? this.$t('errors.teaser_gif_size_error')
        : this.$t('posts.form.edit_teaser_hint')
    },
    publishAt() {
      if (!this.startDate || !this.startTime) {
        return null
      }
      return new Date(`${this.startDate}T${this.startTime}`)
    },
    checkLanguage() {
      return this.$i18n.locale === 'es'
    },
    getContentType() {
      return this.type === 'promo' ? 'video/*, .quicktime' : 'image/*, video/*, .quicktime'
    },
    availableVisibilities() {
      if (this.publicMandatory) {
        this.visibility = 'open'
        return [{text: this.$t('models.post.visibility_types.open'), value: 'open'}]
      }
      if (this.isLoverfans && this.isNewFrontend) {
        return ['open', 'followers', 'premium'].map(v => ({
          text: this.$t(`models.post.visibility_types.${v}`),
          value: v
        }))
      }
      return Visibilities.map(v => ({text: this.$t(`models.post.visibility_types.${v}`), value: v}))
    },
    visibilityDescription() {
      return this.visibility === ''
        ? ''
        : this.$t(`posts.form.type_description.${this.visibility}`)
    },

    isExclusive() {
      return this.visibility === 'exclusive'
    },

    isPremium() {
      return this.isExclusive || this.visibility === 'premium'
    },

    computedSelectedUsers() {
      let users = []
      if (this.selectedAllFans) {
        users.push({text: this.$t('posts.form.all_fans'), key: 'all_fans'})
      }
      if (this.selectedAllFollowers) {
        users.push({text: this.$t('posts.form.all_followers'), key: 'all_followers'})
      }
      users = users.concat(this.selectedUsers)
      return users
    },

    submitable() {
      const validTeaser = !this.hasTeaserError && (!this.teaser.length || !!this.teaser.find(f => f.uploaded))
      const validFiles = !!this.files.length && !this.files.find(f => !f.uploaded)
      const validParticipants = !this.multiplePeople || !!this.participants.length
      const validExclusive = !this.isExclusive || !!this.computedSelectedUsers.length
      return this.validForm && validTeaser && validFiles && validParticipants && validExclusive
    },
    isDraft() {
      return this.post && this.post.draft
    },
    isInvalidDate() {
      if (!this.publishAt) {
        return false
      }
      return this.publishAt.getTime() < new Date().getTime()
    },
    videoPost() {
      return !!(this.files[0] && this.files[0].isVideo())
    },
    canBePromotPost() {
      return false
      // return this.videoPost && project.project === 'loverfans' && this.currentUser.username !== 'GreyFrancisco'
    },
    canHaveTeaser() {
      return this.videoPost && (this.visibility === 'premium' || this.visibility === 'followers')
    },
    formData() {
      return {
        title: this.title,
        content: encodeURI(this.content),
        publishAt: this.publishAt,
        postType: this.type,
        visibility: this.visibility,
        selectedUsers: this.selectedUsers,
        selectedAllFans: this.selectedAllFans,
        selectedAllFollowers: this.selectedAllFollowers,
        price: this.price,
        categoryIds: this.categoryIds,
        mentions: this.mentions,
        promotion: this.promoAvailable,
        teaser: this.teaser.map(f => f.location)[0],
        resources: this.files.map(f => f.location),
        multipleParticipants: this.multiplePeople,
        participants: this.participants.map(p => ({
          dni1Path: p.dni1.location,
          dni2Path: p.dni2.location,
          selfiePath: p.selfie.location,
          agreementPath: p.agreement.location
        }))
      }
    }
  },

  methods: {
    showMentionDialog() {
      this.mentionDialog = true
    },
    updateParticipants({added}) {
      this.participants = added
    },
    teaserAdded(file) {
      this.teaserError = file.name.toLowerCase().endsWith('.gif') && (file.size > MaxTeaserGifSize)
      this.teaser = [file]
    },
    checkPromoPost() {
      if (this.promoAvailable) {
        this.showDates = true
        this.startDate = '2021-06-21'
        this.startTime = '00:00'
      } else {
        this.showDates = false
        this.startDate = null
        this.startTime = null
      }
    },
    addSelectedUser({username}) {
      this.selectedUsers.push(username)
    },

    addMentionedUser(user) {
      if (this.mentions.includes(user.username)) return
      this.mentions.push(user.username)
    },

    removeMentionedUser(username) {
      const index = this.mentions.indexOf(username)
      if (index > -1) this.mentions.splice(index, 1)
    },

    removeSelectedUser(user) {
      if (user === 'all_fans') {
        this.selectedAllFans = false
      } else if (user === 'all_followers') {
        this.selectedAllFollowers = false
      } else {
        const index = this.selectedUsers.findIndex(u => u === user)
        if (index >= 0) {
          this.selectedUsers.splice(index, 1)
        }
      }
    },

    addMultipleUsers(type) {
      if (!this.selectedAllFans && type === 'all_fans') {
        this.selectedAllFans = true
      } else if (!this.selectedAllFollowers && type === 'all_followers') {
        this.selectedAllFollowers = true
      }
    },

    async onSubmit() {
      if (this.type === 'promo') {
        this.promoAvailable = true
      }
      const {data, error} = await PostApi.create(this.formData) // this.$store.dispatch(PostActions.Create, this.formData)
      // TODO notify error
      const notificationMessage = error ? (data.errorCode === 'daily_limit_exceeded' ? this.$t('snackbar.daily_limit_exceeded') : this.$t('snackbar.create_error')) : this.$t('snackbar.create_success')
      this.$eventBus.publish('notification', {
        error,
        message: notificationMessage
      })
      if (!error) {
        this.post = data
        this.$router.push({name: 'post', params: {uuid: data.id}})
        this.close('submit', data)
      }
    },

    close(event, value) {
      this.$emit(event, value)
    }
  },
  async mounted() {
    if (this.attached || this.allFans || this.allFollowers) {
      this.visibility = 'exclusive'
    }
    this.selectedAllFans = this.allFans
    this.selectedAllFollowers = this.allFollowers
    if (this.attached) {
      this.selectedUsers.push(this.attached)
    }
    if (this.publicMandatory) {
      this.visibility = 'open'
    }
  }
}
</script>
<style lang="scss" scoped>
.label {
  width: 120px;

  i {
    margin-right: 5px;
  }
}
</style>
