import Cookies from '@/cookies'
import UserApi from "@/api/UserApi";

const actionsTypes = {
  close: 'close_modal',
  notShow: 'not_show',
  getCampaign: 'get_campaign'
}

const state = () => ({
  active: false,
  isModalClosed: false,
  isActiveBanner: true,
  name: '',
  payload: {
    filename: 'valentine',
    campaign: {
      title: {
        es: '',
        en: ''
      },
      description: {
        es: '',
        en: ''
      }
    },
    banners: {
      color: '#FF5733',
      title: {
        es: '',
        en: ''
      },
      description: {
        es: '',
        en: ''
      }
    }
  }
})

const getters = {
  shouldShowModal: (state) => {
    const cookieName = state.payload.filename || ''
    const cookie = Cookies.get(cookieName) || false
    return state.active && !state.isModalClosed && !cookie
  }
}

const mutations = {
  reset: (s) => {
    s = state()
  },
  close_modal(state) {
    state.isModalClosed = true
  },
  set_campaign(state, config) {
    Object.keys(config).forEach((key) => {
      state[key] = config[key]
    })
  }
}

const actions = {
  [actionsTypes.close]: (context) => {
    context.commit('close_modal')
  },
  [actionsTypes.notShow]: (context) => {
    const cookieModal = context.state.payload.filename || ''
    Cookies.set(cookieModal, true, {
      maxAge: 7 * 24 * 3600 * 1000,
      sameSite: 'strict'
    })
    context.commit('close_modal')
  },
  [actionsTypes.getCampaign]: async (context) => {
    const {data, error} = await UserApi.getCampaign()
    if (!error) {
      context.commit('set_campaign', data)
    } else {
      context.commit('set_campaign', {active: false})
    }
  }
}

export const Actions = actionsTypes

export default {
  namespaceName: 'campaign',
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
