import WithdrawApi from '@/api/WithdrawApi'

const actionsTypes = {
  Request: 'request',
  FetchData: 'fetch_data',
  UpdateData: 'update_data',
  FetchMethods: 'fetch_methods',
  CreateMethod: 'create_method',
  DeleteMethod: 'delete_method',
  FetchCatalog: 'get_catalog',
  LinkPayplume: 'link_payplume',
  CleanPayplume: 'clean_payplume'
}

const state = {
  payplume: {},
  withdrawData: {},
  withdrawMethods: [],
  catalogMethodsActive: []
}

const mutations = {
  reset: (state) => {
    state.withdrawData = {}
    state.withdrawMethods = []
  },

  set_data: (state, config) => {
    state.withdrawData = config
    state.withdrawData.isCompleted = config.fullName && config.country && config.province && config.city && config.postalCode && config.address
  },

  set_methods: (state, methods) => {
    state.withdrawMethods = methods
  },

  add_method: (state, method) => {
    const findIndex = state.withdrawMethods.findIndex(m => m.type === method.type)
    if (findIndex !== -1) {
      state.withdrawMethods.splice(findIndex, 1, method)
    } else {
      state.withdrawMethods.push(method)
    }
  },

  remove_method: (state, method) => {
    const index = state.withdrawMethods.find(m => m.id === method.id)
    state.withdrawMethods.splice(index, 1, method)
  },

  get_catalog: (state, catalog) => {
    state.catalogMethodsActive = catalog
  },
  set_payplume: (state, payplume) => {
    state.payplume = payplume
  }
}

const actions = {
  [actionsTypes.Request]: (context, payload) => {
    return WithdrawApi.create(payload)
  },

  [actionsTypes.FetchData]: async (context) => {
    const { data, error } = await WithdrawApi.getData()
    if (!error) {
      context.commit('set_data', data)
    }
  },

  [actionsTypes.UpdateData]: async (context, payload) => {
    const { data, error } = await WithdrawApi.updateData(payload)
    if (!error) {
      context.commit('set_data', data)
    }
    return { data, error }
  },

  [actionsTypes.FetchMethods]: async (context) => {
    const { data, error } = await WithdrawApi.listMethods()
    if (!error) {
      context.commit('set_methods', data.data)
    }
  },

  [actionsTypes.CreateMethod]: async (context, payload) => {
    const { data, error } = await WithdrawApi.createMethod(payload)
    if (!error) {
      context.commit('set_methods', [data])
    }
  },

  [actionsTypes.DeleteMethod]: async (context, id) => {
    const { error } = await WithdrawApi.deleteMethod(id)
    if (!error) {
      context.commit('set_methods', [])
    }
  },

  [actionsTypes.LinkPayplume]: async (context, { uuid, address, error }) => {
    if (!error) {
      await WithdrawApi.linkPayplume(uuid, address)
    }
    console.log(uuid, address, error)
    context.commit('set_payplume', { show: true, error })
  },

  [actionsTypes.CleanPayplume]: async (context) => {
    context.commit('set_payplume', {})
  },

  [actionsTypes.FetchCatalog]: async (context, id) => {
    const { data, error } = await WithdrawApi.getWithdrawMethodsCatalog()
    if (!error) {
      const catalog = Object.entries(data)
        .filter(([method, countries]) => countries.some(country => country.active))
        .map(([method, countries]) => ({
          type: method,
          countries: countries
            .filter(country => country.active)
            .map(country => country.country)
        }))
      context.commit('get_catalog', catalog)
    }
  }
}

export const Actions = actionsTypes

export default {
  namespaceName: 'withdraws',
  namespaced: true,
  state,
  mutations,
  actions
}
