<template>
  <section>
    <c-dialog
      v-model="dialog"
      :actions="false">
      <template #title>
        <h1>{{ $t('withdraws.withdraw_methods.title') }}</h1>
      </template>
      <template v-if="!method">
        <span>{{ $t('withdraws.withdraw_methods.chose_description') }}</span>
        <v-select v-if="!method"
                  outlined
                  color="primary"
                  :items="availableMethods"
                  style="height: 68px;"
                  v-model="selectedMethod"/>
      </template>
      <MethodInfo v-if="selectedMethod" :selectedMethod="selectedMethod"/>
      <component v-if="selectedMethod"
                 :is="methodForm"
                 :method="method"
                 :selectedMethod="selectedMethod"
                 :payplume="payplumeCopy"
                 @methodAttrs="saveMethodAttrs"
                 @validForm="saveValidForm"
              />

      <v-form v-model="validFormDialog" v-if="selectedMethod" class="p-0 m-0">
        <v-row dense align="center" v-if="requiredFile">
          <v-col cols="8">
            {{ $t('withdraws.withdraw_methods.file_uploaded') }}
            <v-btn
              css="ma-1"
              v-if="isEdit && method.withdrawMethodUrl"
              icon :href="method.withdrawMethodUrl" download>
              <v-icon>file_download</v-icon>
            </v-btn>
          </v-col>
          <v-col cols="1">
            <v-icon v-if="isUploadedAgreement" color="success" right>check_circle</v-icon>
          </v-col>
          <v-col cols="3">
            <FileInput
              button autoupload small
              style="justify-content: flex-end"
              max-files="1"
              content-type="*"
              :loading="(file || {}).loading"
              @file-added="file = $event"
              :rules="fileRequired"
            > {{ isEdit && method.withdrawMethodUrl ? $t('actions.change') : $t('actions.upload') }}
            </FileInput>
          </v-col>
        </v-row>
        <v-row dense align="left">
          <v-checkbox v-model="withdrawMethodApproved"
                      :rules="[$vRequired]"
                      :label="$t('models.user.payment_person.cert_identity')"/>
        </v-row>
      </v-form>
      <v-row justify="end" v-if="selectedMethod">
        <v-col cols="auto">
          <c-btn
            text color="secondary"
            @click="reset"
          >
            {{ $t('actions.cancel') }}
          </c-btn>
        </v-col>
        <v-col cols="auto">
          <c-btn
            :disabled="!validFromSave"
            @click="onSubmit"
          >
            {{ $t('actions.save') }}
          </c-btn>
        </v-col>
      </v-row>
    </c-dialog>
    <!--<PaymentWarning v-if="showWarning" showContinue @continue="confirmSubmit()"/>-->
  </section>

</template>

<script>
import {mapState} from 'vuex'
import dialog from '@/components/mixins/dialog'
import BankMethodForm from '@/components/withdraws/forms/BankMethodForm'
import PayplumeForm from '@/components/withdraws/forms/PayplumeForm'
import {WithdrawActions} from '@/store'
import PaymentWarning from "../custom/warnings/PaymentWarning"
import DefaultMethodForm from "@/components/withdraws/forms/DefaultMethodForm.vue"
import MethodInfo from "@/components/withdraws/infos/MethodInfo.vue"

const MethodTypes = ['bank', 'paxum', 'cosmo', 'e_pay_services', 'skrill', 'paypal', 'western', 'btc', 'payplume']

const MethodForms = {
  bank: BankMethodForm,
  btc: DefaultMethodForm,
  cosmo: DefaultMethodForm,
  paxum: DefaultMethodForm,
  payplume: PayplumeForm,
}

export default {
  name: 'WithdrawMethodDialog',
  components: {PaymentWarning, MethodInfo},
  mixins: [dialog],
  props: {
    value: {type: Boolean},
    dark: {
      type: Boolean,
      default: null
    },
    isEdit: {
      type: Boolean,
      defaults: false
    },
    method: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      methods: MethodTypes,
      selectedMethod: '',
      validForm: false,
      file: null,
      validFormDialog: false,
      withdrawMethodApproved: false,
      showWarning: false,
      payplumeCopy: {},
    }
  },
  computed: {
    ...mapState('withdraws', ['withdrawMethods','catalogMethodsActive', 'withdrawData', 'payplume']),
    fileRequired() {
      if (this.withdrawData.business !== 'first_person') {
        return [this.$vRequired]
      }
      return []
    },
    isUploadedAgreement() {
      return this.file && this.file.uploaded
    },
    availableMethods() {
      console.log(this.catalogMethodsActive)
      console.log(this.withdrawData.country)
      return this.catalogMethodsActive
        .filter(method => method.countries.some(country => country === this.withdrawData.country ))
        .map(method => ({
          text: this.$t(`withdraws.withdraw_methods.types.${method.type}`),
          value: method.type
        }))
    },
    methodForm() {
      return MethodForms[this.selectedMethod] || DefaultMethodForm
    },
    isBank() {
      return this.method.type === 'bank'
    },
    validFromSave() {
      if (this.isEdit) {
        if (!this.isBank) {
          return this.validForm
        } else {
          return this.validForm && this.method.withdrawMethodUrl
        }
      } else {
        return this.validFormDialog && this.validForm && this.file && !this.file.loading
      }
    },
    requiredFile() {
      /* if (this.withdrawData.business === 'first_person') {
        return false
      } */
      const requiredFile = ['bank']
      return requiredFile.findIndex(type =>
        type === this.selectedMethod
      ) !== -1
    }
  },
  watch: {
    value(val) {
      this.dialog = val
    },
    dialog(val) {
      this.$emit('input', val)
    },
    selectedMethod() {
      this.file = {}
    }
  },
  methods: {
    onClose() {
      this.selectedMethod = ''
      this.payplumeCopy = {}
    },
    onSubmit() {
      this.confirmSubmit()
    },
    confirmSubmit() {
      this.showWarning = false
      this.methodAttrs.withdrawMethodPath = this.file.location
      if (this.isEdit && !this.file.location) {
        this.methodAttrs.withdrawMethodPath = this.method.withdrawMethodPath
      }
      this.$store.dispatch(WithdrawActions.CreateMethod, this.methodAttrs)
      this.hideDialog()
    },
    saveValidForm(validForm) {
      this.validForm = validForm
    },
    saveMethodAttrs(attrs) {
      this.methodAttrs = attrs
    }
  },
  mounted() {
    if (this.method) {
      this.selectedMethod = this.method.type
      if (this.isEdit) {
        this.withdrawMethodApproved = true
      }
    }
    if (this.payplume.show) {
      this.dialog = true
      this.selectedMethod = 'payplume'
      this.payplumeCopy = Object.assign({}, this.payplume)
      this.$store.dispatch(WithdrawActions.CleanPayplume)
    }
  }
}
</script>
