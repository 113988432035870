import UserApi from '@/api/UserApi'
import AffiliateApi from '@/api/AffiliateApi'
import SubscriptionApi from '@/api/SubscriptionApi'

const actionsTypes = {
  Search: 'search',
  SearchAffiliate: 'search_affiliate',
  ActiveSubscriptions: 'active_subscriptions',
  Fetch: 'fetch',
  Set: 'set',
  Subscribe: 'subscribe',
  Unsubscribe: 'unsubscribe',
  ToggleFollow: 'toggle_follow'
}
const state = {
  // HashMap of UserStores
  users: {},
  promises: {}
}

const mutations = {
  reset: (state) => {
    state.users = {}
  },

  set_user (state, user) {
    const username = user.username.toLowerCase()
    state.promises[username] = null
    if (state.users[username]) {
      Object.keys(user).forEach(key => {
        state.users[username][key] = user[key]
      })
    } else {
      state.users[username] = user
    }
  },

  set_user_promise (state, username, promise) {
    const lowerUsername = username.toLowerCase()
    state.promises[lowerUsername] = promise
  }
}

const fetchUser = (context, username) => {
  const lower = username.toLowerCase()
  const promise = UserApi.find(username).then(({ data, error }) => {
    context.commit('set_user_promise', lower, null)
    if (!error) {
      if (data.redirectTo) document.location.href = data.redirectTo
      context.commit('set_user', data)
      return context.state.users[lower]
    }
    return data
  })
  context.commit('set_user_promise', lower, promise)
  return promise
}

const actions = {

  [actionsTypes.Fetch]: (context, payload) => {
    if (!payload.username) {
      return null
    }
    const username = payload.username.toLowerCase()
    if (context.state.promises[username]) {
      return context.state.promises[username]
    }
    const user = context.state.users[username]
    if (!user || payload.force) {
      return fetchUser(context, username)
    }

    return user
  },

  [actionsTypes.Set]: (context, user) => {
    context.commit('set_user', user)
  },

  [actionsTypes.Search]: async (context, query) => {
    const { cancelToken } = query
    delete query.cancelToken
    const options = {}
    if (cancelToken) options.cancelToken = cancelToken.token
    const response = await UserApi.list(query, options)
    if (!response.error) {
      response.data.data = response.data.data.map(user => {
        context.commit('set_user', user)
        return context.state.users[user.username.toLowerCase()]
      })
    }
    return response
  },

  [actionsTypes.ActiveSubscriptions]: async (context, query) => {
    const { cancelToken } = query
    delete query.cancelToken
    const options = {}
    if (cancelToken) options.cancelToken = cancelToken.token
    const response = await UserApi.activeSubscriptions(query, options)
    if (!response.error) {
      response.data.data = response.data.data.map(user => {
        context.commit('set_user', user)
        return context.state.users[user.username.toLowerCase()]
      })
    }
    return response
  },

  [actionsTypes.SearchAffiliate]: (context, query) => {
    return AffiliateApi.listUsers(query)
  },

  [actionsTypes.ToggleFollow]: async (context, username) => {
    const user = context.state.users[username.toLowerCase()]
    if (!user) {
      return
    }
    const action = user.following ? UserApi.unfollow : UserApi.follow
    context.commit('set_user', { username, following: !user.following })
    return action(username)
  },

  [actionsTypes.Subscribe]: async (context, payload) => {
    const response = await SubscriptionApi.create(payload)
    if (!response.error) {
      context.dispatch(actionsTypes.Fetch, { username: payload.username, force: true })
    }
    return response
  },

  [actionsTypes.Unsubscribe]: async (context, username) => {
    const response = await SubscriptionApi.cancel(username)
    if (!response.error) {
      context.dispatch(actionsTypes.Fetch, { username, force: true })
    }
    return response
  }
}

export const Actions = actionsTypes

export default {
  namespaceName: 'users',
  namespaced: true,
  state,
  mutations,
  actions
}
