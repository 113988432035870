import { mapState, mapGetters } from "vuex";
import { ChatActions, ProfileActions } from "@/store";
import ConversationsApi from "@/api/conversations/ConversationsApi";
export default {
  data() {
    return {
      showErrorBuyMessage: false,
      showBuyMessage: false,
      messageLocal: this.message,
      showLoadFunds: false,
      loadingButton: false
    };
  },
  computed: {
    ...mapState("profile", ["currentUser"]),
    ...mapState("chats", ["selectedChat", "listMessage"]),
    ...mapGetters("profile", ["totalWalletAmount"]),
    textBuy() {
      if (this.messageLocal.hasOwnProperty("attachments")) {
        const { value, currency } = this.messageLocal.price;
        const totalFiles = this.messageLocal.attachments.length;
        const localePrice = this.$currency(value, currency);
        const feePrice = this.getVat;
        return this.$t("chats.buy_action", {
          price: localePrice,
          fees: feePrice,
          files: totalFiles
        });
      }
      return "";
    },
    getPrice() {
      return this.messageLocal.price;
    },
    getVat () {
      const price = this.messageLocal.price.value
      const vat = (this.billing.vat || 22)
      const expenses = (price * this.billing.exes || 9) / 100
      const vatAmount = Math.ceil((price + expenses) * vat / 100)

      return this.$currency(Math.ceil(vatAmount + expenses), this.messageLocal.price.currency)
    },
    getPriceExes() {
      const billing = this.currentUser.billing;
      const { vat, exes } = billing;
      const price = this.messageLocal.price;
      const priceExes = (price * exes) / 100;
      const priceVat = ((price + priceExes) * vat) / 100;
      return priceVat + priceExes;
    },
    getPriceCurrency() {
      return this.$currency(this.messageLocal.price.value, this.currentUser.price.currency);
    },
    textWarningWallet() {
      const price = this.$currency(this.messageLocal.price.value, this.messageLocal.price.currency);
      return `${this.$t("actions.set_price")}: ${price}*. ${this.$t(
        "payments.wallet_not_enought_amount"
      )}`;
    },
    getBlurUrl() {
      if (this.messageLocal.hasOwnProperty("attachments")) {
        return this.messageLocal.attachments[0].url;
      } else {
        return this.messageLocal.url;
      }
    },
    isVault() {
      return this.message.hasOwnProperty("searchMessage");
    },
    countAttachment() {
      const count = {
        exist: false,
        image: 0,
        video: 0
      };
      if (this.messageLocal.hasOwnProperty("attachments")) {
        this.messageLocal.attachments.forEach(f => {
          if (f.type === "image") {
            count.image++;
          } else {
            count.video++;
          }
          count.exist = true;
        });
      }
      if (this.isVault) {
        count.exist = false;
      }
      return count;
    }
  },
  methods: {
    async showBuy() {
      if (this.messageLocal.searchMessage) {
        await this.getMessage();
      }
      if (this.messageLocal.price.value > this.totalWalletAmount) {
        this.showErrorBuy();
      } else {
        this.showBuyMessage = !this.showBuyMessage;
      }
    },
    async getMessage() {
      const conversationId = this.message.conversationId;
      const conversationMessageId = this.message.messageId;
      const { data, error } = await ConversationsApi.findMessage(
        conversationId,
        conversationMessageId
      );
      if (!error) {
        this.messageLocal = data;
      }
    },
    showFunds() {
      this.showErrorBuyMessage = !this.showErrorBuyMessage;
      this.showLoadFunds = !this.showLoadFunds;
    },
    showErrorBuy() {
      this.showErrorBuyMessage = !this.showErrorBuyMessage;
    },
    async buyMessages() {
      this.loadingButton = true;
      const conversationId = this.selectedChat.id;
      const conversationMessageId = this.messageLocal.id;
      const { data, error } = await ConversationsApi.buyMessage(
        conversationId,
        conversationMessageId
      );
      if (!error) {
        this.updatedConversations(data);
      } else {
        this.showErrorBuy();
      }
      this.loadingButton = false;
      this.showBuy();
    },
    updatedConversations(message) {
      this.$store.dispatch(ProfileActions.Fetch);
      this.$emit("refresh-message", message);
    }
  }
};
