import UnloggedLightLayout from '@/components/layouts/UnloggedLight'
import LandingPage from '@/components/layouts/LandingPage'
import Signup from '@/views/Signup'
import PromoteView from '@/views/PromoteView'
import EmailValidation from '@/views/EmailValidation'
import RememberPassword from '@/views/RememberPassword'
import ChangePassword from '@/views/ChangePassword'
import ScatFaqView from '@/components/scatModal/ScatFaq'
import ImprintView from '@/views/Imprint'
import Complaint2257 from '@/views/2257Complaint'
import DMCA from '@/views/DMCA'
import PromotionOctober from '@/views/promotion/PromotionOctober2'
import CompliancePolicy from '@/views/CompliancePolicy'

import LoverLanding from '@/views/landing/Inicio'
import Discover from '@/views/landing/Discover'

import config from '@/project'
import SpecialCampaign from '@/views/landing/specialCampaign/SpecialCampaign'
import LandingCode from '@/views/landing/PromoCode/LandingCode'
import FullPage from '@/components/layouts/FullPage.vue'
import TempContractConditions from '@/components/contract/TempContractConditions.vue'
import TempTermsConditions from '@/components/terms/TempTermsConditions.vue'
import LegalPolicy from '@/components/legal/LegalPolicy.vue'
import Contract from '@/components/signContract/Contract.vue'
import FullLanding from '@/components/layouts/FullLanding.vue'
import onBoarding from '@/views/onBoarding/OnBoarding.vue'
import jordienp from '@/views/landing/PromoCode/jordienp.vue'
import apolonia from '@/views/landing/PromoCode/apolonia.vue'

const FaqView = () => import(/* webpackChunkName: "group-landing" */ './../../views/Faq.vue')
const ContactView = () => import(/* webpackChunkName: "group-landing" */ './../../views/Contact.vue')
const PrivacyView = () => import(/* webpackChunkName: "group-landing" */ './../../views/Privacy.vue')
const CookiesView = () => import(/* webpackChunkName: "group-landing" */ './../../views/Cookies.vue')
const TutorialsView = () => import(/* webpackChunkName: "group-landing" */ './../../views/TutorialsView.vue')
const Revolution = () => import(/* webpackChunkName: "group-landing" */ './../../views/landing/Revolution.vue')
const Academy = () => import(/* webpackChunkName: "group-landing" */ './../../views/academy/Academy.vue')
const SpecialPromo = () => import(/* webpackChunkName: "group-landing" */ './../../views/landing/specialCampaign/SpecialPromo.vue')
const RRSS = () => import('../../views/landing/RRSS/RRSS.vue')
const Catchment = () => import('../../views/landing/catchment/Catchment.vue')

const Inicio = config.project === 'scatbook' ? LoverLanding : LoverLanding

const fileName = 'march'

const queryString = (query) => {
  return Object.keys(query)
    .map(key => `${key}=${encodeURIComponent(query[key])}`)
    .join('&')
}

const routes = [
  {
    path: '/bsummit',
    redirect: '/promocode/bsummit'
  },
  {
    path: '/sanctuary2023',
    redirect: '/promocode/sanctuary2023'
  },

  {
    path: '/login',
    name: 'login',
    component: Inicio,
    props: true,
    meta: {
      authenticated: false,
      layout_set: true,
      layout: LandingPage
    }
  },
  {
    path: '/promocode',
    component: LandingCode,
    // TODO PAss theme to meta and use it in LandingLayout
    meta: {
      authenticated: false,
      layout_set: true,
      layout: FullLanding
    },
    children: [{
      path: 'bsummit',
      name: 'bsummit',
      props: true,
      component: FullLanding
    }, {
      path: 'desade',
      name: 'desade',
      props: true,
      component: FullLanding
    }, {
      path: 'commupdate2023',
      name: 'commupdate2023',
      props: true,
      meta: {
        authenticated: false
      }
    }, {
      path: 'sanctuary2023',
      name: 'sanctuary2023',
      props: true,
      meta: {
        authenticated: false
      }
    },
      {
        path: 'jordienp',
        name: 'jordienp',
        props: true,
        component: jordienp
      },
      {
        path: 'apolonia',
        name: 'apolonia',
        props: true,
        component: apolonia
      },
      {
        path: '*',
        name: 'promobonus',
        redirect: '/'
      }
    ]
  },
  {
    path: '/signup',
    name: 'signup',
    component: Signup,
    props: true,
    // TODO PAss theme to meta and use it in LandingLayout
    meta: {
      authenticated: false,
      layout_set: true,
      layout: UnloggedLightLayout
    }
  },
  {
    path: '/revolution',
    name: 'revolution',
    component: Revolution,
    // TODO PAss theme to meta and use it in LandingLayout
    meta: {
      layout_set: true,
      layout: LandingPage
    }
  },
  {
    path: '/become_star',
    name: 'become_star',
    component: onBoarding,
    props: true,
    // TODO PAss theme to meta and use it in LandingLayout
    meta: {
      authenticated: true,
      layout_set: true,
      layout: LandingPage
    }
  },
  {
    path: '/discover_star',
    name: 'discover_star',
    component: Discover,
    props: true,
    children: [{
      path: '*',
      redirect: '/discover_star'
    }],
    // TODO PAss theme to meta and use it in LandingLayout
    meta: {
      authenticated: false,
      layout_set: true,
      layout: LandingPage
    }
  },
  {
    path: '/promos/carnival',
    name: 'specialCampaign',
    component: SpecialCampaign,
    props: true,
    // TODO PAss theme to meta and use it in LandingLayout
    meta: {
      layout_set: true,
      layout: LandingPage
    }
  },
  {
    path: '/special/nextdoor',
    name: 'specialPromoIndex',
    component: SpecialPromo,
    props: true,
    children: [{
      path: '',
      name: 'specialPromo',
      component: SpecialPromo
    }, {
      path: ':view',
      name: 'specialPromoView',
      props: true,
      component: SpecialPromo
    }],
    meta: {
      layout_set: true,
      layout: UnloggedLightLayout
    }
  },
  {
    path: '/promote/:step',
    name: 'promote',
    component: PromoteView,
    meta: {
      authenticated: true,
      layout_set: true,
      layout: UnloggedLightLayout
    },
    props: true
  },
  {
    path: '/promote',
    name: 'promote_redirection',
    redirect: '/promote/promotion'
  },
  {
    path: '/email_validation/:email_token',
    name: 'email_validation',
    component: EmailValidation,
    props: true,
    meta: {
      validated: false,
      sidebar: true
    }
  },
  {
    path: '/remember_password',
    name: 'remember_password',
    component: RememberPassword,
    props: true,
    meta: {
      authenticated: false,
      layout_set: true,
      layout: UnloggedLightLayout
    }
  },
  {
    path: '/change_password/:token',
    name: 'change_password',
    component: ChangePassword,
    props: true,
    meta: {
      authenticated: false,
      layout_set: true,
      layout: UnloggedLightLayout
    }
  },
  {
    path: '/faq',
    name: 'faq',
    component: FaqView,
    meta: {
      sidebar: true,
      unlogged_layout: UnloggedLightLayout
    }
  },
  {
    path: '/scat_faq',
    name: 'scat_faq',
    component: ScatFaqView,
    meta: {
      sidebar: true,
      unlogged_layout: UnloggedLightLayout
    }
  },
  {
    path: '/tutorials',
    name: 'tutorials',
    component: TutorialsView,
    meta: {
      sidebar: false,
      unlogged_layout: UnloggedLightLayout
    }
  },
  {
    path: '/academy/:id',
    name: 'academy',
    component: Academy,
    props: true,
    meta: {
      layout_set: true,
      layout: FullPage
    }
  },
  {
    path: '/contact',
    name: 'contact',
    component: ContactView,
    meta: {
      authenticated: true,
      sidebar: true
    }
  },
  {
    path: '/privacy',
    name: 'privacy',
    component: PrivacyView,
    meta: {
      layout_set: true,
      layout: UnloggedLightLayout
    }
  },
  {
    path: '/cookies',
    name: 'cookies',
    component: CookiesView,
    meta: {
      layout_set: true,
      layout: UnloggedLightLayout
    }
  },
  {
    path: '/dmca',
    name: 'dmca',
    component: DMCA,
    meta: {
      layout_set: true,
      layout: UnloggedLightLayout
    }
  },
  {
    path: '/complaint_2257',
    name: 'complaint_2257',
    component: Complaint2257,
    meta: {
      layout_set: true,
      layout: UnloggedLightLayout
    }
  },
  {
    path: '/compliance-policy',
    name: 'compliance-policy',
    component: CompliancePolicy,
    meta: {
      layout_set: true,
      layout: UnloggedLightLayout
    }
  },
  {
    path: '/rrss',
    name: 'rrss',
    component: RRSS,
    meta: {
      layout_set: true,
      layout: UnloggedLightLayout
    }
  },
  {
    path: '/catchment/:id',
    name: 'catchment',
    component: Catchment,
    props: true,
    meta: {
      layout_set: true,
      layout: FullPage
    }
  },
  {
    path: '/campaigns/:id',
    name: 'campmaigns',
    meta: {
      authenticated: false
    }
  },
  {
    path: '/contract-conditions',
    component: TempContractConditions,
    name: 'contract-conditions',
    meta: {
      layout_set: true,
      layout: UnloggedLightLayout
    }
  },
  {
    path: '/conditions/20230904',
    name: 'conditionsOld',
    component: TempTermsConditions,
    meta: {
      layout_set: true,
      layout: UnloggedLightLayout
    }
  },
  {
    path: '/conditions',
    name: 'conditions',
    component: LegalPolicy,
    meta: {
      layout_set: true,
      layout: UnloggedLightLayout
    }
  },
  {
    path: '/contract',
    name: 'signContract',
    component: Contract,
    meta: {
      layout_set: true,
      layout: FullLanding
    }
  },
  {
    path: '/avn/star',
    name: 'avn_star',
    beforeEnter: (to, from, next) => {
      window.location.href = `https://${config.locales.site}/campaigns/promocode/avn/index.html?`
      next(false)
    }
  },
  {
    path: '/avn/follower',
    name: 'avn_follower',
    beforeEnter: (to, from, next) => {
      window.location.href = `https://${config.locales.site}/campaigns/promocode/avn/index.html?fan=false`
      next(false)
    }
  },
  {
    path: '/avn/contest',
    name: 'avn_contest',
    beforeEnter: (to, from, next) => {
      window.location.href = `https://${config.locales.site}/campaigns/promocode/avn/contest/index.html`
      next(false)
    }
  },
  {
    path: '/lalexpo/stars',
    name: 'lalexpo_stars',
    beforeEnter: (to, from, next) => {
      window.location.href = `https://${config.locales.site}/campaigns/promocode/lalexpo/index.html`
      next(false)
    }
  },
  {
    path: '/lalexpo/concurso',
    name: 'lalexpo_concurso',
    beforeEnter: (to, from, next) => {
      window.location.href = `https://${config.locales.site}/campaigns/promocode/lalexpo/contest/index.html`
      next(false)
    }
  },
  {
    path: '/contenido-especial/cecilia-sopena',
    name: 'especial_cecilia',
    beforeEnter: (to, from, next) => {
      window.location.href = `https://${config.locales.site}/campaigns/promo/ceciliasopena/index.html`
      next(false)
    }
  },
  {
    path: '/loverdays/spring-sale-2025',
    name: 'loverdays_spring_sale_2025',
    beforeEnter: (to, from, next) => {
      window.location.href = `${config.domains.baseUrl}/campaigns/promo/${fileName}/index.html?${queryString(to.query)}`
      next(false)
    }
  },
  {
    path: '/scatdays/spring-sale-2025',
    name: 'scatdays_spring_sale_2025',
    beforeEnter: (to, from, next) => {
      window.location.href = `${config.domains.baseUrl}/campaigns/promo/${fileName}/index.html?${queryString(to.query)}`
      next(false)
    }
  },
  {
    path: '/darkdays/spring-sale-2025',
    name: 'darkdays_spring_sale_2025',
    beforeEnter: (to, from, next) => {
      window.location.href = `${config.domains.baseUrl}/campaigns/promo/${fileName}/index.html?${queryString(to.query)}`
      next(false)
    }
  },
  {
    path: '/loverdays/spring-sale-2025/academy',
    name: 'loverdays_spring_sale_2025_academy',
    beforeEnter: (to, from, next) => {
      window.location.href = `${config.domains.baseUrl}/campaigns/promo/${fileName}/academy.html?${queryString(to.query)}`
      next(false)
    }
  },
  {
    path: '/scatdays/spring-sale-2025/academy',
    name: 'scatdays_spring_sale_2025_academy',
    beforeEnter: (to, from, next) => {
      window.location.href = `${config.domains.baseUrl}/campaigns/promo/${fileName}/academy.html?${queryString(to.query)}`
      next(false)
    }
  },
  {
    path: '/darkdays/spring-sale-2025/academy',
    name: 'darkdays_spring_sale_2025_academy',
    beforeEnter: (to, from, next) => {
      window.location.href = `${config.domains.baseUrl}/campaigns/promo/${fileName}/academy.html?${queryString(to.query)}`
      next(false)
    }
  },
  {
    path: '/the-home-of-kink',
    name: 'the_home_of_kink',
    beforeEnter: (to, from, next) => {
      window.location.href = `${config.domains.baseUrl}/campaigns/publish/traffic/index.html?${queryString(to.query)}`
      next(false)
    }
  },
  {
    path: '/the-home-of-scat',
    name: 'the_home_of_scat',
    beforeEnter: (to, from, next) => {
      window.location.href = `${config.domains.baseUrl}/campaigns/publish/traffic/index.html?${queryString(to.query)}`
      next(false)
    }
  },
  {
    path: '/venus-berlin/register-now',
    name: 'venus-berlin-register-now',
    beforeEnter: (to, from, next) => {
      window.location.href = `${config.domains.baseUrl}/campaigns/events/venus/index-qr.html${queryString(to.query)}`
      next(false)
    }
  }
]
// /darkdays/welcome-summer?man=true
export default routes
export const LandingRouteNames = [
  'bsummit',
  'desade',
  'commupdate2023',
  'sanctuary2023',
  'jordienp',
  'apolonia',
  'promobonus',
  'become_star',
  'discover_star',
  'specialCampaign',
  'specialPromoIndex',
  'specialPromo',
  'specialPromoView',
  'promote',
  'promote_redirection',
  'email_validation',
  'change_password',
  'faq',
  'scat_faq',
  'tutorials',
  'academy',
  'contact',
  'privacy',
  'cookies',
  'dmca',
  'complaint_2257',
  'compliance-policy',
  'rrss',
  'catchment',
  'campmaigns',
  'contract-conditions',
  'conditionsOld',
  'conditions',
  'signContract',
  'avn_star',
  'avn_follower',
  'avn_contest',
  'lalexpo_stars',
  'lalexpo_concurso',
  'especial_cecilia',
  'venus-berlin-register-now',
  'the_home_of_kink',
  'the_home_of_scat',
  'loverdays_spring_sale_2025',
  'darkdays_spring_sale_2025',
  'scatdays_spring_sale_2025',
  'loverdays_spring_sale_2025_academy',
  'darkdays_spring_sale_2025_academy',
  'scatdays_spring_sale_2025_academy'
]
