<template>
  <v-card-text>
    <p>{{ $t('payments.posts.success_message_1', { username: post.username }) }}</p>
    <p>{{ $t('payments.posts.success_message_2') }}</p>
    <v-row>
      <v-col cols="6">
          <c-img class="rounded post mini"
            :src="post.preview.preview">
          </c-img>
        </v-col>
        <v-col cols="6">
          <UserAvatar :profile="false" :user="post.author" />
          <div class="my-8">
            <v-icon>subscriptions</v-icon>
            {{ post.title }}
          </div>
          <div class="my-8">
            <v-icon>attach_money</v-icon>
            <Currency :amount="price" />
          </div>
          <c-btn @click="close" :target="redirect ? '_blank' : ''">{{ $t('actions.show_post') }}</c-btn>
        </v-col>
    </v-row>
  </v-card-text>
</template>
<script>
import { mapState } from 'vuex'
import { PostActions } from '@/store'
import UserAvatar from '@/components/users/UserAvatar'
import config from '@/project'

export default {
  name: 'PostInfo',
  components: { UserAvatar },
  props: {
    id: [String, Number]
  },
  data () {
    return {
      post: { preview: '' },
      redirect: config.payments.darkfans_redirect
    }
  },
  computed: {
    ...mapState('profile', ['currentUser']),
    price () {
      return this.post.price + this.vatPrice
    },
    vatPrice () {
      return Math.ceil(this.post.price * this.currentUser.billing.vat / 100)
    }
  },
  methods: {
    close () {
      if (!this.post.visible) {
        this.$store.dispatch(PostActions.Fetch, { id: this.id, force: true })
      }
      if (config.payments.darkfans_redirect) {
        window.location = `https://darkfans.com/posts/${this.id}?oa=${this.currentUser.sessionToken}&utm_source=${config.project}`
        return
      }
      this.$router.replace({ name: 'post', params: { uuid: this.id } })
      this.$emit('close')
    }
  },
  async beforeMount () {
    this.post = await this.$store.dispatch(PostActions.Fetch, { id: this.id, force: true })
  }
}
</script>
<style lang="scss">
.post.mini {
  max-height: 224px;
  max-width: 224px;
}
</style>
