import app, {Actions as AppActions} from '@/store/application'
import user, {Actions as UserActions} from '@/store/modules/users'
import preferences, {Actions as PreferencesActions} from '@/store/preferences'
import ProfileApi from '@/api/ProfileApi'
import SubscriptionApi from '@/api/SubscriptionApi'
import i18n from '@/i18n'
import {currency} from '../plugins/currency'

const UpdateNotifications = `${app.namespaceName}/${AppActions.UpdateHasNotifications}`
const UpdateMessages = `${app.namespaceName}/${AppActions.UpdateHasMessages}`
const SetUser = `${user.namespaceName}/${UserActions.Set}`
const FetchUser = `${user.namespaceName}/${UserActions.Fetch}`
const UpdateTagPreferences = `${preferences.namespaceName}/${PreferencesActions.SetTagPrefs}`

const actionsTypes = {
  Fetch: 'fetch',
  UpdateAccountData: 'update_account_data',
  UpdatePersonalData: 'update_personal_data',
  AcceptTerms: 'accept_terms',
  AcceptStarTerms: 'accept_star_terms',
  UpdateSubscriptionOffers: 'update_subscription_offers',
  CreateTrial: 'create_trial',
  SelectTrial: 'select_trial',
  DisableTrial: 'disable_trial',
  FetchTrials: 'fetch_trials',
  Set: 'set',
  UpdateWallet: 'set_wallet',
  UpdatePromotion: 'update_promotion',
  BlockCountry: 'block_country',
  UnblockCountry: 'unblock_country',
  BlockUser: 'block_user',
  UnblockUser: 'unblock_user',
  FeatureUser: 'feature_user',
  UnfeatureUser: 'unfeature_user',
  UpdateSubscriptionPrice: 'update_subscription_price',
  UpdateYotiSession: 'update_yoti_session'
}

const state = {
  currentUser: {},
  promotion: {},
  wallet: {},
  eurWallet: {},
  usdWallet: {},
  tags: [],
  bannedCountries: [],
  blockedUsers: [],
  trials: [],
  featuredUsers: [],
  selectedTrial: null,
  isInfluencer: false,
  isFollower: false,
  isCompliance: false,
  isAccountManager: false,
  isContentManager: false,
  isAgent: false,
  isAdmin: false,
  isController: false,
  personalDataApproved: false,
  payout: {},
  withdraw: [],
  subscriptionPrice: 0,
  yotiSessionId: ''
}

const getters = {
  currentWallet: (state) => {
    if (!state.currentUser.billing) return {currency: 'EUR'}
    return state.currentUser.billing.currency === 'EUR' ? state.eurWallet : state.usdWallet
  },

  totalWalletAmount: (state, getters, rootState) => {
    const currentWallet = getters.currentWallet
    const hasBonus = state.currentUser.wallet.bonus
    if (hasBonus) {
      currentWallet.amount = currentWallet.amount + hasBonus.amount
    }
    const otherWallet = currentWallet.currency === 'EUR' ? state.usdWallet : state.eurWallet
    const {exchanges} = rootState.application.billing
    const exchange = exchanges.find(ex => ex.fromCurrency === otherWallet.currency)
    return currentWallet.amount + (otherWallet.amount * exchange.exchange)
  }
}

const mutations = {
  reset: (state) => {
    state.currentUser = {}
    state.promotion = {}
    state.wallet = {}
    state.eurWallet = {}
    state.usdWallet = {}
    state.tags = {}
    state.bannedCountries = []
    state.blockedUsers = []
    state.trials = []
    state.isInfluencer = false
    state.isFollower = false
    state.isCompliance = false
    state.isAccountManager = false
    state.isContentManager = false
    state.isAgent = false
    state.isAdmin = false
    state.isController = false
    state.payout = {}
    state.withdraw = []
    state.personalDataApproved = false
    state.subscriptionPrice = 0
    state.yotiSessionId = ''
  },

  set_user: (state, user) => {
    state.currentUser = user
    state.promotion = user.promotion || {}
    state.wallet = user.wallet || {currency: 'EUR'}
    const wallets = user.wallets || []
    state.eurWallet = wallets.find(w => w.currency === 'EUR') || state.wallet
    state.usdWallet = wallets.find(w => w.currency === 'USD') || {currency: 'USD'}
    state.tags = user.tags || []
    state.bannedCountries = user.bannedCountries || []
    state.blockedUsers = user.blockedUsers || []
    state.featuredUsers = user.featuredUsers || []
    if (user.subscriptionOffers.length === 0) {
      user.subscriptionOffers.forEach(offer => {
        offer.minPrice = offer.type === 'month' ? 100 : 500
      })
    }
    state.trials = user.subscriptionTrials || []
    state.isInfluencer = user && user.role === 'influencer'
    state.isFollower = user && user.role === 'follower'
    state.isCompliance = user && user.role === 'compliance'
    state.isAccountManager = user && user.role === 'account_manager'
    state.isContentManager = user && user.role === 'content_manager'
    state.isAdmin = user && user.role === 'admin'
    state.isController = user && user.role === 'controller'
    state.isAgent = user && user.isAgent
    state.payout = user.payout
    state.withdraw = user.withdraw
    state.personalDataApproved = user.personalDataApproved
    i18n.loadLocale(user.lang)
  },

  set_attrs: (state, attrs) => {
    Object.keys(attrs).forEach(key => {
      state.currentUser[key] = attrs[key]
    })
  },

  set_promotion: (state, promotion) => {
    if (state.currentUser) {
      state.currentUser.promotion = promotion
    }
    state.promotion = promotion
  },

  set_wallet: (state, wallet) => {
    if (state.currentUser) {
      state.currentUser.wallet = wallet
    }
    state.wallet = wallet
  },

  add_trial: (state, trial) => {
    state.currentUser.subscriptionTrials.push(trial)
    state.trials = state.currentUser.subscriptionTrials
  },

  remove_trial: (state, trial) => {
    const index = state.trials.findIndex(t => t.id === trial.id)
    if (index !== -1) {
      state.currentUser.subscriptionTrials.splice(index, 1)
      state.trials = state.currentUser.subscriptionTrials
    }
  },

  set_trials: (state, trials) => {
    state.currentUser.subscriptionTrials = trials
    state.trials = trials
  },

  set_selected_trial: (state, trial) => {
    state.selectedTrial = trial
  },

  set_banned_countries: (state, countries) => {
    state.bannedCountries = countries
  },

  add_banned_country: (state, country) => {
    if (state.bannedCountries.find(c => c === country)) {
      return
    }
    state.bannedCountries.push(country)
    state.bannedCountries.sort()
  },

  remove_banned_country: (state, country) => {
    state.bannedCountries = state.bannedCountries.filter(c => c !== country)
  },

  set_blocked_users: (state, users) => {
    state.blockedUsers = users
  },

  add_blocked_user: (state, user) => {
    if (state.blockedUsers.find(u => u.username === user.username)) {
      return
    }
    state.blockedUsers.push(user)
    state.blockedUsers.sort((user1, user2) => user1.username < user2.username)
  },

  remove_blocked_user: (state, user) => {
    state.blockedUsers = state.blockedUsers.filter(u => u.username !== user.username)
  },

  add_featured_user: (state, user) => {
    if (state.featuredUsers.find(u => u === user.username)) {
      return
    }
    state.featuredUsers.push(user)
    state.featuredUsers.sort((user1, user2) => user1.username < user2.username)
  },

  remove_featured_user: (state, user) => {
    state.featuredUsers = state.featuredUsers.filter(u => u.username !== user.username)
  },
  set_subscription_price: (state, subscriptionPrice) => {
    state.subscriptionPrice = subscriptionPrice
  },
  set_yoti_session: (state, yotiSessionId) => {
    state.yotiSessionId = yotiSessionId
  }
}

const actions = {
  [actionsTypes.Fetch]: async (context) => {
    const {data, error} = await ProfileApi.profile()
    if (!error) {
      if (data.redirectTo) document.location.href = data.redirectTo
      context.dispatch('set', data)
      currency.setCurrency(data.billing.currency)
      if (data.blocked) {
        context.dispatch('session/logout', data, {root: true})
        return {error: true}
      }
    }
    return {data, error}
  },

  [actionsTypes.AcceptTerms]: async (context) => {
    const response = await ProfileApi.acceptTerms({global: true})
    context.dispatch(actionsTypes.Fetch)
    return response
  },

  [actionsTypes.AcceptStarTerms]: async (context) => {
    const response = await ProfileApi.acceptTerms({star: true})
    context.dispatch(actionsTypes.Fetch)
    return response
  },

  [actionsTypes.UpdateAccountData]: async (context, attrs) => {
    const response = await ProfileApi.updateAccountData(attrs)
    context.dispatch(actionsTypes.Fetch)
    return response
  },

  [actionsTypes.UpdatePersonalData]: async (context, attrs) => {
    const response = await ProfileApi.updatePersonalData(attrs)
    context.dispatch(actionsTypes.Fetch)
    return response
  },

  [actionsTypes.UpdateSubscriptionOffers]: async (context, attrs) => {
    const response = await ProfileApi.updateSubscriptionOffers(attrs)
    if (!response.error) {
      context.commit('set_attrs', response.data)
    }
    return response
  },

  [actionsTypes.CreateTrial]: async (context, trial) => {
    const response = await SubscriptionApi.createTrial(trial)
    if (!response.error) {
      context.commit('add_trial', response.data)
    }
    return response
  },

  [actionsTypes.SelectTrial]: async (context, trial) => {
    context.commit('set_selected_trial', trial)
  },

  [actionsTypes.DisableTrial]: async (context, id) => {
    const response = await SubscriptionApi.disableTrial(id)
    if (!response.error) {
      context.commit('remove_trial', {id})
    }
    return response
  },

  [actionsTypes.FetchTrials]: async (context, attrs) => {
    const response = await ProfileApi.listTrials(attrs)
    if (!response.error) {
      context.commit('set_trials', response.data)
    }
    return response
  },

  [actionsTypes.UpdatePromotion]: async (context, attrs) => {
    const response = await ProfileApi.requestPromotion(attrs)
    if (!response.error) {
      if (attrs.subscriptionPrice) {
        context.commit('set_attrs', {subscriptionPrice: attrs.subscriptionPrice})
      }
      context.commit('set_promotion', response.data)
    }
    return response
  },

  [actionsTypes.UpdateWallet]: (context, wallet) => {
    context.commit('set_wallet', wallet)
  },

  [actionsTypes.Set]: (context, user) => {
    context.commit('set_user', user)
    context.dispatch(SetUser, user, {root: true})
    context.dispatch(UpdateMessages, user.alerts.hasUnreadMessages, {root: true})
    context.dispatch(UpdateNotifications, user.alerts.hasNotifications, {root: true})
    context.dispatch(UpdateTagPreferences, user.tagPreferences, {root: true})

    ProfileApi.listBlockedCountries().then(({
                                              data,
                                              error
                                            }) => {
      if (!error) {
        context.commit('set_banned_countries', data)
      }
    })
    ProfileApi.listBlockedUsers().then(({
                                          data,
                                          error
                                        }) => {
      if (!error) {
        context.commit('set_blocked_users', data.data)
      }
    })
  },

  // FIXME move this to mediators
  [actionsTypes.BlockUser]: async (context, user) => {
    context.commit('add_blocked_user', user)
    const response = await ProfileApi.blockUser(user.username)
    context.dispatch(FetchUser, {
      username: user.username,
      force: true
    }, {root: true})
    if (response.error) {
      context.commit('remove_blocked_user', user)
    }
    return response
  },

  // FIXME move this to mediators
  [actionsTypes.UnblockUser]: async (context, user) => {
    context.commit('remove_blocked_user', user)
    const response = await ProfileApi.unblockUser(user.username)
    context.dispatch(FetchUser, {
      username: user.username,
      force: true
    }, {root: true})
    if (response.error) {
      context.commit('add_blocked_user', user)
    }
    return response
  },

  [actionsTypes.FeatureUser]: async (context, user) => {
    context.commit('add_featured_user', user)
    const response = await ProfileApi.featureUser(user.username)
    return response
  },

  [actionsTypes.UnfeatureUser]: async (context, user) => {
    context.commit('remove_featured_user', user)
    const response = await ProfileApi.unfeatureUser(user.username)
    return response
  },

  [actionsTypes.BlockCountry]: (context, country) => {
    ProfileApi.blockCountry(country)
    context.commit('add_banned_country', country)
  },
  [actionsTypes.UnblockCountry]: (context, country) => {
    ProfileApi.unblockCountry(country)
    context.commit('remove_banned_country', country)
  },
  [actionsTypes.UpdateSubscriptionPrice]: (context, subscriptionPrice) => {
    context.commit('set_subscription_price', subscriptionPrice)
  },
  [actionsTypes.UpdateYotiSession]: (context, yotiSessionId) => {
    context.commit('set_yoti_session', yotiSessionId)
  }
}

export const Actions = actionsTypes

export default {
  namespaceName: 'profile',
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
